/**
 * @author Akshay Kumar Singh
 * @file AddTask Component
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { encryptData } from '../../shared/utils/crypto.util';
import * as CONSTANT from '../../shared/constants/data';
import LANGUAGECONST from '../../shared/constants/language';
import Validator from '../../shared/validator';
import { spinnerService } from '../../shared/services/spinner.service';
import { CallApi } from '../../shared/services/api.service';
import * as taskDataAction from '../../redux/actions/taskDataAction';
import { getPostDataWithAction } from '../../redux/actions/common_action';
import CONFIG from '../../configuration';
import ROUTES from '../../shared/constants/routes';
import AlertModal from '../../shared/modals/AlertModal';
import { getData, removeData } from '../../shared/utils/storage.util';
import { formatDate, sortArrObj } from '../../shared/utils/helper.util';
import HeaderComponent from '../Common/HeaderComponent';
import QuesListComp from './QuesListComp';

class AddTaskComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            auditorList: [],
            reviewerList: [],
            lineList: [],
            assetsList: [],
            assetsTypeList: [
                { id: 1, asset_type: 'Work Order Compliance' },
                { id: 2, asset_type: 'Parts Room  Inventory Compliance' },
                { id: 3, asset_type: ' Line Equipment and Injection' },
                { id: 4, asset_type: 'Facilities' },
                { id: 5, asset_type: 'Utilities' }
            ],
            completionPercentage: '',
            frequencyList: [
                { id: 1, value: 'Weekly' },
                { id: 2, value: 'Monthly' },
                { id: 3, value: 'Quaterly' },
                { id: 4, value: 'Semi-Annual' },
                { id: 5, value: 'Annual' }
            ],
            estimatedDate: new Date(),
            task_start_date: null,
            task_end_date: null,
            auditor: '',
            reviewer: '',
            assetsType: 0,
            assets: '',
            type: '',
            utility_static_ques: '',
            ar_reviewer_id: '',
            ar_reviewer_name: '',
            frequency: 'Weekly',
            lineType: '',
            taskData: getData('taskData'),
            quesList: [],
            updateQuesArr: [],
            updatedQuesFlag: false,
            enableBtn: false,
            mhActivityName: '',
            dvActivityName: '',
            activityContainMH: false,
            activityContainDV: false,
            selectMH: false,
            selectDV: false,
            tempQuesData: [],
            woQuesList: [],
            apiQuesObj: [],
            typeList: [],
            reviewerQuesObj: [],
            auditorName: '',
            reviewerName: '',
            containerStyle: {},
            isLineType: true,
            isAsset: true,
            areaAsset: [],
            filterActivities: [],
            checkWOSearch: false,
            reviewerMail: '',
            auditorMail: '',
            fileFormData: null,
            fileName: '',
            ohqData: [],
            ohqCheck: false,
            excelFiledata: [],
            isEnable: false,
            isPlantManager: false,
            woListData: [],
            woList: [],
            partsConsumptionCount: {},
            completionArr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            utility_static_ques_arr: [
                'HP Compressor',
                'LP Compressor',
                'Chiller',
                'Carbon Towers',
                'RO',
                'Boiler',
                'Mineral Skid',
                'Ozone Skid',
                'Waste Water',
                'Cooling Towers',
                'None'
            ],
            totPartsData: null,
            facility_static_ques_arr: ['Electrical', 'Grounds', 'Roof', 'None']
        };
        // this.setAuditorData();
        // this.setReviewerData();
    }

    /**
     * @description This function call once the component load at first time and sets value to the state variable on the basis of Task Information.
     * @returns Changes in state values and on the basses of user it may be call the function to get the reviewer data.
     */
    componentDidMount() {
        if (this.props.location?.state?.fromEdit == 1) {
            this.setState({
                containerStyle: {
                    minHeight: '200px',
                    backgroundColor: '#ffffff'
                }
            });
        }
        if (!getData('reviewer')) {
            const taskData = {
                taskData: getData('taskData')
            };
            this.props.actions.taskData(taskData);
            this.getAssetType();
            this.getUserListing();
            if (this.props.history.location.state.fromEdit == 1) {
                this.setState({
                    auditorName: this.state.taskData?.auditor_name,
                    reviewerName: this.state.taskData?.reviewer_name,
                    assetsType: this.state.taskData?.task_name,
                    lineType: this.state.taskData?.line,
                    assets: this.state.taskData?.asset,
                    type: this.state.taskData?.type,
                    frequency: this.state.taskData?.frequency,
                    estimatedDate: new Date(this.state.taskData.estimate_date)
                });
            }
        } else {
            this.setState({
                containerStyle: { minHeight: '110px' }
            });
            this.getReviewerData();
        }
    }

    /**
     * @description This function used to call the api to fetch the reviewer data.
     */
    getReviewerData = () => {
        let language = this.props?.langData?.language || 'en';
        const dataObj = {
            task_id: this.props.location.state.task_id,
            asset_type_id: this.state.assetsType
        };
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchingData
        );
        CallApi('POST', CONFIG.previewList, {}, dataObj, '', 'previewList', this, language);
    };

    /**
     * @description This function sets value to the state variable on the basis of reviewer data.
     * @param response Response of reviewer data api.
     * @returns Changes in state values i.e. reviewerQuesObj
     */
    previewList = (response) => {
        if (response.success) {
            this.setState({
                reviewerQuesObj: response.response
            });
        }
    };

    /**
     * @description This function acll api to fetch the questions.
     */
    getQuestion = () => {
        let language = this.props?.langData?.language || 'en';
        let { assets, utility_static_ques } = this.state;
        const quesObj = {
            asset_type_id: parseInt(this.state.assetsType),
            plant_id: getData('plantDetails')
                ? getData('plantDetails').plant_code
                : getData('createdAuditObj')
                ? getData('createdAuditObj').plantCode
                : '',
            asset: assets
        };
        if (utility_static_ques) {
            quesObj.utility_static_ques = utility_static_ques;
        }
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchingQuestion
        );
        CallApi('POST', CONFIG.getQuesList, {}, quesObj, '', 'questionList', this, language);
    };

    /**
     * @description This function get the response of questions.
     * @param response Response data of question
     * @returns Changes in state values and call the function to set the activity
     */
    questionList = (response) => {
        let { assetsType } = this.state;
        if (response.success) {
            this.setState(
                {
                    quesData: response.response,
                    quesList: response?.response
                },
                () => {
                    let { quesList } = this.state;
                    if (assetsType == 3 || assetsType == 4 || assetsType == 5) {
                        let activityContainMH = false,
                            selectMH = false,
                            mhActivityName = '';
                        let activityContainDV = false,
                            selectDV = false,
                            dvActivityName = '';
                        let filteredQuesList = quesList.filter((item) => !!item['activity']);
                        let typeList = filteredQuesList.map((item) => item['activity']);
                        if (assetsType == 5 || assetsType == 4) {
                            typeList.push('None');
                        }
                        if (assetsType == 3) {
                            let filterActivities = typeList.filter((activity, index) => {
                                if (typeList.indexOf(activity) === index) {
                                    if (activity.indexOf('MH') > -1) {
                                        activityContainMH = true;
                                        mhActivityName = activity;
                                    } else if (activity.indexOf('DV') > -1) {
                                        activityContainDV = true;
                                        dvActivityName = activity;
                                    } else {
                                        if (
                                            filteredQuesList[index].long_heading !=
                                                'Machine Health' &&
                                            filteredQuesList[index].long_heading !=
                                                'General Audit' &&
                                            filteredQuesList[index].long_heading != ''
                                        ) {
                                            return activity;
                                        }
                                    }
                                }
                            });
                            if (activityContainMH || activityContainDV) {
                                filterActivities.push('None');
                                this.setState({
                                    activityContainMH,
                                    mhActivityName,
                                    selectMH,
                                    activityContainDV,
                                    dvActivityName,
                                    selectDV
                                });
                            }
                            this.setActivity(filterActivities);
                        } else {
                            let filterActivities = typeList.filter(
                                (activity, index) => typeList.indexOf(activity) === index
                            );
                            this.setActivity(filterActivities);
                        }
                    }
                }
            );
        }
    };

    /**
     * @description This function sets value to the state variable on the basis of activities.
     * @param filterActivities filterActivities is filter activities data.
     * @returns Changes in state values i.e. filter activities, type list and type.
     */
    setActivity = (filterActivities) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        this.setState(
            {
                filterActivities: filterActivities,
                typeList: filterActivities,
                type: ''
            },
            () => {
                if (!this.state.typeList || !this.state.typeList.length)
                    AlertModal.showAlert(lang.STRINGS.NODATAFOUND);
            }
        );
    };

    /**
     * @description This function calls the api to fetch the users list.
     */
    getUserListing = () => {
        let language = this.props?.langData?.language || 'en';
        let { userData } = getData('loginData');
        let { role_name } = userData;
        let data = userData.role_name === 'audit manager' ? { role_name } : {};
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchUserData
        );
        CallApi('POST', CONFIG.userListing, {}, data, '', 'userList', this, language);
    };

    /**
     * @description This function calls the api to fetch the work order list.
     */
    getWorkOrderList = () => {
        let language = this.props?.langData?.language || 'en';
        const WorkOrderListObj = {};
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchingData
        );
        CallApi(
            'POST',
            CONFIG.workOrderList,
            {},
            WorkOrderListObj,
            '',
            'workOrderList',
            this,
            language
        );
    };

    /**
     * @description This function sets value to the state variables on the basis of users list
     * @param response response is the data recieved from the user list api
     * @returns Changes in state values.
     */
    userList = (response) => {
        if (response.success) {
            let auditor, reviewer, ar_reviewer_id, ar_reviewer_name;
            let { auditorList, reviewerList, taskData } = this.state;
            response.response.result.forEach((data, index) => {
                if (taskData?.auditor_id === data.user_id) {
                    auditor = data.user_id;
                }
                if (taskData?.reviewer_id === data.user_id) {
                    reviewer = data.user_id;
                }
                if (taskData?.ar_reviewer_id === data.user_id) {
                    ar_reviewer_id = data.user_id;
                    ar_reviewer_name = taskData?.ar_reviewer_name;
                    this.setState({
                        isPlantManager: true,
                        ar_reviewer_id: data.user_id,
                        ar_reviewer_name
                    });
                }
            });
            let plant_id = getData('plantDetails')
                ? getData('plantDetails').plant_id
                : getData('createdAuditObj')
                ? getData('createdAuditObj').plantId
                : {};
            response.response.result.forEach((data, index) => {
                if (
                    data.role_name == 'auditor' ||
                    data.role_name == 'audit manager' ||
                    (plant_id && data.role_name == 'plant manager' && data.plant_id == plant_id)
                ) {
                    auditorList.push(data);
                }
                if (
                    data.role_name == 'reviewer' ||
                    data.role_name == 'audit manager' ||
                    (plant_id && data.role_name == 'plant manager' && data.plant_id == plant_id)
                ) {
                    reviewerList.push(data);
                }
            });
            let userList = response.response.result.filter(
                (data) => data.role_name == 'audit manager' || data.role_name == 'reviewer'
            );
            this.setState(
                {
                    userList: sortArrObj(userList, 'first_name'),
                    auditorList: sortArrObj(auditorList, 'first_name'),
                    reviewerList: sortArrObj(reviewerList, 'first_name'),
                    auditor: auditor || auditorList[0]?.user_id,
                    reviewer: reviewer || reviewerList[0]?.user_id,
                    ar_reviewer: ar_reviewer_id || userList[0]?.user_id
                },
                () => {
                    this.setAuditorData();
                    this.setReviewerData();
                }
            );
        }
    };

    /**
     * @description This function sets value to the state variable on the basis of activity
     * @param updateQuesArr Updated question array which will be used to send the question
     * @returns Changes in state values i.e. updatedQuesArr and updateduesFlag to true.
     */
    updateQuestionArr = (updateQuesArr) => {
        this.setState({ updateQuesArr, updatedQuesFlag: true });
    };

    /**
     * @description This function used to show the questions on browser
     * @param assetsType Type of asset.
     * @returns Return the question list component to show the questions
     */
    getAssetType = (assetsType) => {
        let { type, quesList, utility_static_ques, updatedQuesFlag, lineType, selectMH, selectDV } =
            this.state;
        if (type) {
            return (
                <QuesListComp
                    QuesList={quesList}
                    lineType={lineType}
                    assetsType={assetsType}
                    activity={type}
                    selectMH={selectMH}
                    selectDV={selectDV}
                    updateQuestionArr={this.updateQuestionArr}
                    updatedQuesFlag={updatedQuesFlag}
                    utility_static_ques={utility_static_ques}
                />
            );
        }
    };

    /**
     * @description This function sets value to the state variable on the basis of name and value
     * @returns Changes in state values.
     */
    handleFieldChange = (event) => {
        let { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    /**
     * @description This function used to set the auditor and reviewer
     */
    handleChange = (event) => {
        let { name, value, selectedIndex } = event.target;
        let { auditor, reviewer, isPlantManager } = this.state;
        let role_name = event.target[selectedIndex].getAttribute('role_name');
        if (role_name === 'plant manager') {
            this.setState({ auditor: value, reviewer: value, isPlantManager: true }, () => {
                this.setAuditorData();
                this.setReviewerData();
            });
        } else {
            this.setState(
                {
                    auditor: name === 'auditor' ? value : isPlantManager ? '' : auditor,
                    reviewer: name === 'reviewer' ? value : isPlantManager ? '' : reviewer,
                    isPlantManager: false,
                    ar_reviewer_id: '',
                    ar_reviewer_name: ''
                },
                () => {
                    this.setAuditorData();
                    this.setReviewerData();
                }
            );
        }
    };

    /**
     * @description This function sets value to the state variable on the basis of auditor data.
     * @returns Changes in state values i.e. auditor name & auditor email.
     */
    setAuditorData = () => {
        let { auditorList, auditor } = this.state;
        auditorList.forEach((data) => {
            if (data.user_id == auditor) {
                this.setState({
                    auditorName: `${data.first_name} ${data.last_name}`,
                    auditorMail: data.email
                });
            }
        });
        if (!auditor) {
            this.setState({
                auditor: auditorList[0].user_id,
                auditorName: `${auditorList[0].first_name} ${auditorList[0].last_name}`,
                auditorMail: auditorList[0].email
            });
        }
    };

    /**
     * @description This function sets value to the state variable on the basis of reviewer data
     * @returns Changes in state values i.e. reviewer name & reviewer email
     */
    setReviewerData = () => {
        let { reviewerList, reviewer } = this.state;
        reviewerList.forEach((data, index) => {
            if (data.user_id == reviewer) {
                this.setState({
                    reviewerName: `${data.first_name} ${data.last_name}`,
                    reviewerMail: data.email
                });
            }
        });
        if (!reviewer) {
            this.setState({
                reviewer: reviewerList[0].user_id,
                reviewerName: `${reviewerList[0].first_name} ${reviewerList[0].last_name}`,
                reviewerMail: reviewerList[0].email
            });
        }
    };

    /**
     * @description This function sets value to the state variable on the basis of handle change event.
     * @returns Changes in state values i.e. name and AR reviewer name.
     */
    setArReviewer = (event) => {
        let { name, value, selectedIndex } = event.target;
        let ar_name = event.target[selectedIndex].getAttribute('ar_name');
        this.setState({
            [name]: value,
            ar_reviewer_name: ar_name
        });
    };

    /**
     * @description This function used to set the value of audit type also call function based on audit type.
     */
    handleAssetType = (event) => {
        let { name, value } = event.target;
        if (value == 1 || value == 2) {
            this.setState(
                {
                    [name]: value,
                    utility_static_ques: '',
                    isLineType: false,
                    lineList: ['NA'],
                    lineType: 'NA',
                    assetsList: [{ asset_number: 'NA' }],
                    assets: 'NA',
                    typeList: ['NA'],
                    type: 'NA',
                    isAsset: false,
                    updatedQuesFlag: false
                },
                () => {
                    this.getQuestion();
                }
            );
        } else {
            this.setState(
                {
                    [name]: value,
                    utility_static_ques: '',
                    isLineType: false,
                    lineList: [],
                    lineType: '',
                    assetsList: [],
                    assets: '',
                    typeList: [],
                    type: '',
                    isAsset: false,
                    updatedQuesFlag: false
                },
                () => {
                    this.getLine();
                }
            );
        }
    };

    /**
     * @description This function used to set the value of asset type also call the api to fetch the question based on audit type.
     */
    handleAssetChange = (event) => {
        let { assetsType } = this.state;
        this.setState(
            {
                [event.target.name]: event.target.value,
                type: '',
                utility_static_ques: '',
                updatedQuesFlag: false,
                typeList: [],
                mhActivityName: '',
                activityContainMH: false,
                selectMH: false,
                dvActivityName: '',
                activityContainDV: false,
                selectDV: false
            },
            () => {
                if (assetsType != 5) {
                    this.getQuestion();
                }
            }
        );
    };

    /**
     * @description This function used to handle the additional utilities questions.
     */
    handleAdditionalUtility_Ques = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value }, () => this.getQuestion());
    };

    /**
     * @description This function used to handle the activity of an audit type.
     */
    handleActivity = (event) => {
        let { name, value } = event.target;
        let { selectMH, assetsType, utility_static_ques } = this.state;
        if (!selectMH && value == 'None' && assetsType == 3) {
            return AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.NoneMH
            );
        }
        if (assetsType == 4 && value == 'None' && utility_static_ques == 'None') {
            return AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.NoneGA
            );
        }
        if (assetsType == 5 && value == 'None' && utility_static_ques == 'None') {
            return AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.NoneGA
            );
        }
        this.setState(
            {
                [name]: value,
                updatedQuesFlag: false
            },
            () => {
                this.getSelectedQuestion(this.state.type);
            }
        );
    };

    /**
     * @description This function sets value to the state variable on the basis of activity and checkbox.
     * @returns Changes in state values i.e. selectMH
     */
    handleMH = () => {
        let { selectMH, type, activityContainDV, selectDV } = this.state;
        if (type == 'None' && selectMH) {
            if (activityContainDV) {
                if (!selectDV) {
                    return AlertModal.showAlert(
                        LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.NoneActivity
                    );
                }
            } else {
                return AlertModal.showAlert(
                    LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.NoneActivity
                );
            }
        }
        if (activityContainDV && !selectMH) {
            this.setState(
                {
                    selectDV: false,
                    updatedQuesFlag: false
                },
                () => {
                    this.getSelectedQuestion(this.state.type);
                }
            );
        }
        this.setState(
            {
                selectMH: !selectMH,
                updatedQuesFlag: false
            },
            () => {
                this.getSelectedQuestion(this.state.type);
            }
        );
    };

    /**
     * @description This function sets value to the state variable on the basis of activity and checkbox.
     * @returns Changes in state values i.e. selectDV
     */
    handleDV = () => {
        let { selectDV, type, activityContainMH, dvActivityName } = this.state;
        if (!selectDV) {
            if (activityContainMH) {
                console.log('here???');
                this.setState(
                    {
                        selectMH: false,
                        updatedQuesFlag: false,
                        type: 'None',
                        selectDV: true
                    },
                    () => {
                        this.getSelectedQuestion(dvActivityName);
                    }
                );
            }
            return;
        }
        this.setState(
            {
                selectDV: false,
                updatedQuesFlag: false,
                type: ''
            },
            () => {
                this.getSelectedQuestion(this.state.type);
            }
        );
    };

    /**
     * @description This function used to get the selected question bassed on activity and set the value to state.
     * @param type type is the selected activity
     * @returns Changes in state values i.e. tempQuesData
     */
    getSelectedQuestion = (type) => {
        this.state.quesList.forEach((data, index) => {
            if (data.activity == type) {
                this.setState({
                    tempQuesData: data
                });
            }
        });
    };

    /**
     * @description This function used to call the api to fetch the line based on plant.
     */
    getLine = () => {
        let language = this.props?.langData?.language || 'en';
        const dataObj = {
            plant_id: getData('plantDetails')
                ? getData('plantDetails').plant_code
                : getData('createdAuditObj')
                ? getData('createdAuditObj').plantCode
                : ''
        };
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchLine
        );
        CallApi('POST', CONFIG.getLineList, {}, dataObj, '', 'getLineList', this, language);
    };

    /**
     * @description This function used to handle the response of line api.
     * @param response Response of the line api
     */
    getLineList = (response) => {
        if (response.success) {
            if (this.state.assetsType == 3) {
                this.setState({
                    isLineType: false,
                    lineList: response.response.filter(
                        (data) => data.includes('LINE') || data.includes('INJECT')
                    )
                });
            } else if (this.state.assetsType == 4) {
                this.setState({
                    isLineType: false,
                    lineList: response.response.filter(
                        (data) =>
                            data.includes('FACILITIES') ||
                            data.includes('LGV') ||
                            data.includes('ADMINISTRATION')
                    )
                });
            } else if (this.state.assetsType == 5) {
                this.setState({
                    isLineType: false,
                    lineList: response.response.filter(
                        (data) =>
                            !data.includes('LINE') &&
                            !data.includes('INJECT') &&
                            !data.includes('FACILITIES') &&
                            !data.includes('LGV')
                    )
                });
            }
        }
    };

    /**
     * @description This function used to handle the line change also calls the function to fetch the assets.
     * @returns Changes in state values based on line change.
     */
    handleLineChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
                type: '',
                utility_static_ques: '',
                assetsList: [],
                typeList: [],
                mhActivityName: '',
                activityContainMH: false,
                selectMH: false,
                dvActivityName: '',
                activityContainDV: false,
                selectDV: false
            },
            () => {
                this.getAsset();
            }
        );
    };

    /**
     * @description This function used to call the api to get the assets.
     */
    getAsset = () => {
        let language = this.props?.langData?.language || 'en';
        const dataObj = {
            plant_id: getData('plantDetails')
                ? getData('plantDetails').plant_code
                : getData('createdAuditObj')
                ? getData('createdAuditObj').plantCode
                : '',
            line_id: this.state.lineType
        };
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchAssets
        );
        CallApi('POST', CONFIG.getAssetList, {}, dataObj, '', 'getAssetList', this, language);
    };

    /**
     * @description This function used to handle the api response of asset api.
     * @param response Response of the asset api
     */
    getAssetList = (response) => {
        if (response.success) {
            if (this.state.assetsType == 3) {
                this.setState({
                    isAsset: false,
                    assetsList: response.response,
                    isType: false
                });
            } else {
                this.setState({
                    isAsset: false,
                    assetsList: response.response,
                    isType: false
                });
            }
        }
    };

    /**
     * @description This function used to handle the date and sets the state based on date.
     * @param name Name of the state variable in which value is to be set
     * @param date Date that will be set.
     * @returns Changes in state values i.e. Date, task start date and task end date.
     */
    handleDate = (name, date) => {
        let { task_start_date, task_end_date } = this.state;
        let value = null;
        if (name === 'task_end_date' && task_start_date < date) {
            this.setState({
                [name]: date
            });
        } else if (name === 'task_start_date') {
            this.setState({
                [name]: date,
                task_end_date: task_end_date > date ? task_end_date : null
            });
        } else {
            this.setState({
                task_start_date: null,
                task_end_date: null
            });
        }
    };

    /**
     * @description This function handle the tab change.
     * @param index Index of tab
     */
    tabChange = (event, index) => {
        const tablinks = document.getElementsByClassName('tablinks');
        for (let i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace('active', '');
        }
        event.currentTarget.className += 'active';
    };

    /**
     * @description This function used to call the api to submit the audit.
     */
    handleSubmit = async (event) => {
        const mhDVValidation = (hasMH, hasDV, mh, dv, type) => {
            if (hasDV && hasMH) {
                if (!mh && !dv) {
                    if (type === 'None' || type === '') {
                        return false;
                    }
                    return true;
                }
                if (!dv && mh) {
                    if (type === '') {
                        return false;
                    }
                    return true;
                }
            }
            return true;
        };
        event.preventDefault();
        let language = this.props?.langData?.language || 'en';
        this.setState({
            ohqCheck: false
        });
        await this.setAuditorData();
        await this.setReviewerData();
        let { page, audit_id, fromAdd, fromEdit } = this.props.history.location.state;
        let {
            auditor,
            reviewer,
            auditorName,
            reviewerName,
            assets,
            assetsType,
            type,
            lineType,
            isPlantManager,
            ar_reviewer_id,
            ar_reviewer_name,
            frequency,
            estimatedDate,
            task_start_date,
            task_end_date,
            updateQuesArr,
            woList,
            partsConsumptionCount,
            totPartsData,
            selectMH,
            selectDV,
            activityContainMH,
            activityContainDV
        } = this.state;

        if (
            !Validator.allValid() ||
            (isPlantManager && !ar_reviewer_id) ||
            !mhDVValidation(activityContainMH, activityContainDV, selectMH, selectDV, type)
        ) {
            Validator.showMessages();
            AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.MandatoryFields
            );
            this.forceUpdate();
        } else {
            const taskAndAuditTypeMatch = (data) => {
                if (data.category === data.taskType) {
                    return { matches: true, message: '' };
                }
                return {
                    matches: false,
                    message:
                        data.category === 'Diagnostic Visit'
                            ? LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS
                                  .AuditIsDVType
                            : LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS
                                  .AuditIsNotDVType
                };
            };
            if (fromAdd == 2 && fromEdit == 0) {
                if (this.state.assetsType != 2) {
                    const addtaskObj = {
                        audit_status: page == 3 ? 1 : '',
                        task_name: parseInt(assetsType),
                        audit_id: audit_id || getData('auditData').audit_id,
                        plant_code: getData('plantDetails')
                            ? getData('plantDetails').plant_code
                            : getData('createdAuditObj')
                            ? getData('createdAuditObj').plantCode
                            : '',
                        auditor_id: auditor,
                        reviewer_id: reviewer,
                        auditor_name: auditorName,
                        reviewer_name: reviewerName,
                        line: lineType,
                        asset: assets,
                        type,
                        frequency,
                        asset_type_id: parseInt(assetsType) || 1, //1 for work order
                        estimate_date: Date.parse(estimatedDate),
                        task_start_date: task_start_date ? formatDate(task_start_date) : '',
                        task_end_date: task_end_date ? formatDate(task_end_date) : '',
                        reviewer_mail: this.state.reviewerMail,
                        auditor_mail: this.state.auditorMail,
                        manager_mail: getData('loginData').userData.email,
                        ques: updateQuesArr,
                        wo_list: woList,
                        pm_mh_activity:
                            type === 'None' && (selectMH || selectDV)
                                ? 1
                                : type !== 'None' && selectMH
                                ? 2
                                : type !== 'None' && !selectMH
                                ? 0
                                : '',
                        value_of_parts: totPartsData,
                        parts_consumption_count: partsConsumptionCount,
                        ar_reviewer_id: ar_reviewer_id ? ar_reviewer_id : null,
                        ar_reviewer_name: ar_reviewer_name ? ar_reviewer_name : null,
                        category: selectDV ? CONFIG.DVCat : CONFIG.ARMACat,
                        taskType: selectDV ? CONFIG.DVCat : CONFIG.ARMACat
                    };
                    const matchingTypes = taskAndAuditTypeMatch(addtaskObj);
                    if (!matchingTypes.matches) {
                        AlertModal.showAlert(matchingTypes.message);
                        return;
                    }
                    if (isPlantManager) {
                        addtaskObj.role_name = 'plant manager';
                    }
                    spinnerService.show(
                        'Oval',
                        LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.CreatingTask
                    );
                    CallApi(
                        'POST',
                        CONFIG.createTask,
                        {},
                        addtaskObj,
                        '',
                        'createTask',
                        this,
                        language
                    );
                } else {
                    this.createPRAudit();
                }
            }
            if (fromAdd == 1 && fromEdit == 0) {
                if (this.state.assetsType == 2) {
                    this.createPRAudit();
                } else {
                    const dataObj = {
                        audit_name: getData('createdAuditObj').auditName,
                        plant_id: getData('createdAuditObj').plantId,
                        plant_code: getData('createdAuditObj').plantCode,
                        user_id: getData('loginData').userData.user_id,
                        audit_type: 'a',
                        start_date: getData('createdAuditObj').auditBeginDate,
                        end_date: getData('createdAuditObj').auditEndDate,
                        last_audit_date: getData('createdAuditObj').lastAuditDate,
                        task_name: assetsType,
                        auditor_id: auditor,
                        auditor_name: auditorName,
                        reviewer_id: reviewer,
                        reviewer_name: reviewerName,
                        line: lineType,
                        asset: assets,
                        type,
                        frequency,
                        asset_type_id: parseInt(assetsType) || 1, //1 for work order
                        estimate_date: Date.parse(estimatedDate),
                        task_start_date: task_start_date ? formatDate(task_start_date) : '',
                        task_end_date: task_end_date ? formatDate(task_end_date) : '',
                        reviewer_mail: this.state.reviewerMail,
                        auditor_mail: this.state.auditorMail,
                        manager_mail: getData('loginData').userData.email,
                        ques: updateQuesArr,
                        wo_list: woList,
                        pm_mh_activity:
                            type === 'None' && (selectMH || selectDV)
                                ? 1
                                : type !== 'None' && selectMH
                                ? 2
                                : type !== 'None' && !selectMH
                                ? 0
                                : '',
                        value_of_parts: totPartsData,
                        parts_consumption_count: partsConsumptionCount,
                        ar_reviewer_id: ar_reviewer_id ? ar_reviewer_id : null,
                        ar_reviewer_name: ar_reviewer_name ? ar_reviewer_name : null,
                        category: getData('createdAuditObj').category,
                        taskType: selectDV ? CONFIG.DVCat : CONFIG.ARMACat
                    };
                    const matchingTypes = taskAndAuditTypeMatch(dataObj);
                    if (!matchingTypes.matches) {
                        AlertModal.showAlert(matchingTypes.message);
                        return;
                    }
                    if (isPlantManager) {
                        dataObj.role_name = 'plant manager';
                    }
                    spinnerService.show(
                        'Oval',
                        LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.CreatingAudit
                    );
                    CallApi(
                        'POST',
                        CONFIG.createAudit,
                        {},
                        dataObj,
                        '',
                        'createAudit',
                        this,
                        language
                    );
                }
            }
            if (fromEdit == 1 && fromAdd == 0) {
                const dataObj = {
                    task_name: assetsType,
                    auditor_id: auditor,
                    auditor_name: auditorName,
                    reviewer_name: reviewerName,
                    reviewer_id: reviewer,
                    line: lineType,
                    asset: assets,
                    reviewer_mail: this.state.reviewerMail,
                    auditor_mail: this.state.auditorMail,
                    manager_mail: getData('loginData').userData.email,
                    task_id: this.props.history.location.state.selectedTaskData.task_id,
                    plant_manager_id: isPlantManager ? auditor : '',
                    plant_manager_name: isPlantManager ? auditorName : '',
                    reviewer_flag: 0,
                    ar_reviewer_id: ar_reviewer_id ? ar_reviewer_id : null,
                    ar_reviewer_name: ar_reviewer_name ? ar_reviewer_name : null,
                    ar_reviewer_flag: 0,
                    estimated_audit_date: this.state.estimatedDate
                };
                spinnerService.show(
                    'Oval',
                    LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.UpdatingTask
                );
                CallApi('POST', CONFIG.updateTask, {}, dataObj, '', 'updateTask', this, language);
            }
        }
    };

    /**
     * @description This function used to call the api to submit the parts room audit.
     */
    createPRAudit = () => {
        let language = this.props?.langData?.language || 'en';
        let checkVlm = getData('plantDetails')
            ? getData('plantDetails').isVlm
            : getData('createdAuditObj')
            ? getData('createdAuditObj').isVlm
            : '';
        let { page, audit_id, fromAdd, fromEdit } = this.props.history.location.state;
        let {
            auditor,
            reviewer,
            auditorName,
            reviewerName,
            assets,
            assetsType,
            type,
            lineType,
            isPlantManager,
            ar_reviewer_id,
            ar_reviewer_name,
            frequency,
            estimatedDate,
            task_start_date,
            task_end_date,
            updateQuesArr,
            ohqData,
            completionPercentage
        } = this.state;
        if (completionPercentage || (isPlantManager && !ar_reviewer_id)) {
            if (fromAdd == 1 && fromEdit == 0) {
                if (this.state.assetsType == 2 && ohqData.length) {
                    const dataObj = {
                        audit_name: getData('createdAuditObj').auditName,
                        plant_id: getData('createdAuditObj').plantId,
                        plant_code: getData('createdAuditObj').plantCode,
                        user_id: getData('loginData').userData.user_id,
                        audit_type: 'a',
                        start_date: getData('createdAuditObj').auditBeginDate,
                        end_date: getData('createdAuditObj').auditEndDate,
                        last_audit_date: getData('createdAuditObj').lastAuditDate,
                        task_name: assetsType,
                        auditor_id: auditor,
                        auditor_name: auditorName,
                        reviewer_id: reviewer,
                        reviewer_name: reviewerName,
                        line: lineType,
                        asset: assets,
                        type,
                        frequency,
                        asset_type_id: parseInt(assetsType) || 1, //1 for work order
                        estimate_date: Date.parse(estimatedDate),
                        task_start_date: task_start_date ? formatDate(task_start_date) : '',
                        task_end_date: task_end_date ? formatDate(task_end_date) : '',
                        reviewer_mail: this.state.reviewerMail,
                        auditor_mail: this.state.auditorMail,
                        manager_mail: getData('loginData').userData.email,
                        parts_min_count: completionPercentage,
                        pm_mh_activity: '',
                        ques: updateQuesArr,
                        ohq_data: ohqData,
                        ar_reviewer_id: ar_reviewer_id ? ar_reviewer_id : null,
                        ar_reviewer_name: ar_reviewer_name ? ar_reviewer_name : null,
                        category: getData('createdAuditObj').category
                    };
                    if (isPlantManager) {
                        dataObj.role_name = 'plant manager';
                    }
                    spinnerService.show(
                        'Oval',
                        LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.CreatingAudit
                    );
                    CallApi(
                        'POST',
                        CONFIG.createAudit,
                        {},
                        dataObj,
                        '',
                        'createAudit',
                        this,
                        language
                    );
                }
            } else if (fromAdd == 2 && fromEdit == 0) {
                const addtaskObj = {
                    audit_status: page == 3 ? 1 : '',
                    task_name: parseInt(assetsType),
                    audit_id: audit_id || getData('auditData').audit_id,
                    plant_code: getData('plantDetails')
                        ? getData('plantDetails').plant_code
                        : getData('createdAuditObj')
                        ? getData('createdAuditObj').plantCode
                        : '',
                    auditor_id: auditor,
                    reviewer_id: reviewer,
                    auditor_name: auditorName,
                    reviewer_name: reviewerName,
                    line: lineType,
                    asset: assets,
                    type,
                    frequency,
                    asset_type_id: parseInt(assetsType) || 1, //1 for work order
                    estimate_date: Date.parse(estimatedDate),
                    task_start_date: task_start_date ? formatDate(task_start_date) : '',
                    task_end_date: task_end_date ? formatDate(task_end_date) : '',
                    reviewer_mail: this.state.reviewerMail,
                    auditor_mail: this.state.auditorMail,
                    manager_mail: getData('loginData').userData.email,
                    parts_min_count: completionPercentage,
                    pm_mh_activity: '',
                    ques: updateQuesArr,
                    ohq_data: ohqData,
                    ar_reviewer_id: ar_reviewer_id ? ar_reviewer_id : null,
                    ar_reviewer_name: ar_reviewer_name ? ar_reviewer_name : null,
                    category: getData('createdAuditObj').category
                };
                if (isPlantManager) {
                    addtaskObj.role_name = 'plant manager';
                }
                spinnerService.show(
                    'Oval',
                    LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.CreatingTask
                );
                CallApi(
                    'POST',
                    CONFIG.createTask,
                    {},
                    addtaskObj,
                    '',
                    'createTask',
                    this,
                    language
                );
            }
        } else {
            AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.AllFieldsRequired
            );
        }
    };

    /**
     * @description This function used to call the api to fetch the OHQ data.
     */
    getOhqDataList = async () => {
        let language = this.props?.langData?.language || 'en';
        const ohqObj = {
            plant_code: getData('plantDetails')
                ? getData('plantDetails').plant_code
                : getData('createdAuditObj')
                ? getData('createdAuditObj').plantCode
                : ''
        };
        let checkVlm = getData('plantDetails')
            ? getData('plantDetails').isVlm
            : getData('createdAuditObj')
            ? getData('createdAuditObj').isVlm
            : '';
        if (checkVlm != 'Yes' || !!this.state.fileFormData) {
            spinnerService.show(
                'Oval',
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchingEBSData
            );
            await CallApi('POST', CONFIG.getOhqList, {}, ohqObj, '', 'getOhqList', this, language);
        } else {
            AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FileUploadRequired
            );
        }
    };

    /**
     * @description This function used to handle the response of OHQ list api and call the function to set the OHQ file data.
     * @param response Response of the OHQ api
     * @returns Changes in state values
     */
    getOhqList = (response) => {
        let { enableBtn, ohqData, excelFiledata } = this.state;
        if (response.success) {
            AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.DataRetrievedSuccess
            );
            this.setState(
                {
                    ohqData: response.response.ohq_data,
                    ohqCheck: true
                },
                () => {
                    this.setFileOhqData();
                }
            );
        }
    };

    /**
     * @description This function sets OHQ data to the state variables
     */
    setFileOhqData = () => {
        let checkVlm = getData('plantDetails')
            ? getData('plantDetails').isVlm
            : getData('createdAuditObj')
            ? getData('createdAuditObj').isVlm
            : '';
        let { enableBtn, ohqData, excelFiledata } = this.state;
        let count = 0;
        if (checkVlm === 'Yes') {
            if (enableBtn && ohqData && ohqData.length && excelFiledata && excelFiledata.length) {
                spinnerService.show(
                    'Oval',
                    LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS
                        .RestructureDEBSData
                );
                const excelFileDataObj = {};
                excelFiledata.forEach((data) => {
                    excelFileDataObj[data.item] = data;
                });
                for (let data of ohqData) {
                    if (excelFileDataObj[data.item]) {
                        count++;
                        data.locator = excelFileDataObj[data.item].locator;
                        data.lot_no = excelFileDataObj[data.item].lot_no;
                    }
                }
                spinnerService.hide('Oval');
                this.setState(
                    {
                        ohqData,
                        isEnable: true
                    },
                    () => {}
                );
            }
        } else {
            this.setState({
                isEnable: true
            });
        }
    };

    /**
     * @description This function used to handle the response create task.
     * @param response Response of the create task api
     */
    createTask = (response) => {
        if (response.success) {
            AlertModal.showAlert(response.message);
            setTimeout(() => {
                AlertModal.hideAlert();
                this.props.history.goBack();
            }, 2000);
        }
    };

    /**
     * @description This function used to handle the response create audit
     * @param response Response of the create audit api
     */
    createAudit = (response) => {
        if (response.success) {
            AlertModal.showAlert(response.message);
            setTimeout(() => {
                AlertModal.hideAlert();
                return this._handleRedirection(response.response?.audit_id);
            }, 2000);
        }
    };

    /**
     * @description This function used to handle the redirection to route.
     * @param audit_id Audit ID to get the route
     */
    _handleRedirection(audit_id) {
        const createdAuditObj = getData('createdAuditObj');
        let urlToRedirect = ROUTES.DASHBOARD;
        if (audit_id && createdAuditObj.plantname) {
            urlToRedirect = ROUTES.AUDITDETAILS.replace(
                ':plantName/:auditId',
                encryptData(createdAuditObj.plantname) + '/' + audit_id
            );
        }
        let routeObj = {
            pathname: urlToRedirect
        };
        if (audit_id && createdAuditObj.plantname) {
            routeObj.state = {
                page: 1,
                createdAuditObj,
                audit_id,
                fromAdd: 2
            };
        }
        this.props.history.push(routeObj);
    }

    /**
     * @description This function handle the response of update task api
     * @param response Response of update task api.
     */
    updateTask = (response) => {
        if (response.success) {
            AlertModal.showAlert(response.message);
            setTimeout(() => {
                AlertModal.hideAlert();
                this.props.history.goBack();
            }, 2000);
        }
    };

    /**
     * @description This function used to call the api for searching the work order.
     */
    serachWO = () => {
        let language = this.props?.langData?.language || 'en';
        const { task_start_date, task_end_date } = this.state;
        if (task_start_date && task_end_date) {
            const searchWObj = {
                plant_code: getData('plantDetails')
                    ? getData('plantDetails').plant_code
                    : getData('createdAuditObj')
                    ? getData('createdAuditObj').plantCode
                    : '',
                task_start_date: task_start_date ? formatDate(task_start_date) : '',
                task_end_date: task_end_date ? formatDate(task_end_date) : ''
            };
            spinnerService.show(
                'Oval',
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.SearchingWorkOrder
            );
            CallApi(
                'POST',
                CONFIG.fetchDateWorkOrder,
                {},
                searchWObj,
                '',
                'searchDateWO',
                this,
                language
            );
        } else {
            AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.SDEDMandatory
            );
        }
    };

    /**
     * @description This function handle the response of search work order api also call the function to set the work order data.
     * @param response Response of the search work order api.
     */
    searchDateWO = (response) => {
        const lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        if (response.success) {
            AlertModal.showAlert(
                `${lang.LABLES.Found} ${response.response.wo_count} ${lang.LABLES.WorkOrders}`
            );
            this.setState(
                {
                    checkWOSearch: true,
                    woListData: response.response.wo_list,
                    woList: [],
                    partsConsumptionCount: response.response.parts_consumption_count
                },
                () => {
                    this.setWoListData();
                }
            );
        }
    };

    /**
     * @description This function sets value of the work order list
     */
    setWoListData = () => {
        const { woListData, woList } = this.state;
        const lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let obj = {};
        for (let data of woListData) {
            if (obj[data.WORK_ORDER]) {
                obj[data.WORK_ORDER]['labour_cost'] += data.labour_cost;
                obj[data.WORK_ORDER]['labour_hours'] += data.labour_hours;
                obj[data.WORK_ORDER]['cost_parts'] += data.cost_parts;
                obj[data.WORK_ORDER]['quantity_parts'] += data.quantity_parts;
            } else {
                obj[data.WORK_ORDER] = data;
            }
        }

        for (let item of Object.values(obj)) {
            this.state.totPartsData += item.cost_parts * item.quantity_parts;
            woList.push(item);
        }
        this.setState({}, () => {});
        AlertModal.showAlert(`${lang.LABLES.Found} ${woList.length} ${lang.LABLES.WorkOrders}
    ${lang.LABLES.WorkOrderPM}: ${woList.filter((x) => x.wo_type === 'WORK ORDERS - PM').length}
    ${lang.LABLES.WorkOrderRebuild}: ${
            woList.filter((x) => x.wo_type === 'WORK ORDERS - REBUILD').length
        }
    ${lang.LABLES.WorkOrderRepair}: ${
            woList.filter((x) => x.wo_type === 'WORK ORDERS - REPAIR').length
        }
    ${lang.LABLES.WorkOrderWarranty}: ${
            woList.filter((x) => x.wo_type === 'WORK ORDERS - WARRANTY').length
        }`);
    };

    /**
     * @description This function used to handle the CSV file.
     */
    handleFile = (event) => {
        let { files } = event.target;
        let fileData = files[0];
        if (
            fileData &&
            (fileData.type === 'application/vnd.ms-excel' ||
                fileData.type ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                fileData.type === 'application/vnd.ms-excel.sheet.macroEnabled.12')
        ) {
            this.setState(
                {
                    fileFormData: fileData,
                    fileName: `(${fileData.name})`
                },
                () => {
                    this.handleCsvUpload();
                }
            );
        } else {
            this.setState(
                {
                    fileFormData: '',
                    fileName: ''
                },
                () => {}
            );
            removeData('csvFileData');
            event.target.value = null;
            AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.PleaseUploadExcel
            );
        }
    };

    /**
     * @description This function used to call the api to upload the csv file.
     */
    handleCsvUpload = async () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let formData = new FormData();
        formData.append('file', this.state.fileFormData);
        await this.props.uploadFile(
            CONFIG.uploadCSV,
            formData,
            'ADD TASK',
            this.handleFileCall,
            lang.STRINGS.UploadFile,
            this,
            language
        );
    };

    /**
     * @description This function used to handle the response of csv file upload api.
     * @param response Response of the csv upload api.
     */
    handleFileCall = async (response) => {
        if (response.success) {
            this.setState(
                {
                    excelFiledata: response.response.csv_data
                },
                () => {
                    this.setState({
                        enableBtn: true
                    });
                }
            );
        }
    };

    handleInputChange = (event) => {
        this.setState({
            estimatedDate: event
        });
    };

    render() {
        let vlmCheck = getData('plantDetails')
            ? getData('plantDetails').isVlm
            : getData('createdAuditObj')
            ? getData('createdAuditObj').isVlm
            : 'Yes';
        let { fromEdit } = !!this.props.location.state ? this.props.location.state : 0;
        let {
            userList,
            auditor,
            auditorList,
            reviewer,
            reviewerList,
            ar_reviewer_id,
            ar_reviewer_name,
            assetsTypeList,
            assetsType,
            lineList,
            isPlantManager,
            task_start_date,
            task_end_date,
            isEnable,
            updateQuesArr,
            lineType,
            mhActivityName,
            dvActivityName,
            activityContainMH,
            activityContainDV,
            selectMH,
            selectDV,
            assetsList,
            assets,
            utility_static_ques_arr,
            utility_static_ques,
            checkWOSearch,
            fileName,
            enableBtn,
            facility_static_ques_arr
        } = this.state;
        // Plant name/Org code/Audit name
        return (
            <div className="main-cntnt-wrap">
                <HeaderComponent
                    history={this.props.history}
                    page={this.props.location?.state?.page || 1}
                    language={this.props.langData}
                />
                <div className="container">
                    <ol className="breadcrumb">
                        <li>
                            <span>
                                <b>
                                    {getData('plantName')} {'\u27f6'}
                                </b>
                            </span>{' '}
                            <b>{getData('auditName')}</b>
                        </li>
                    </ol>
                    <div className="add-task-outer" style={this.state.containerStyle}>
                        {!getData('reviewer') ? (
                            <form autoComplete="off" onSubmit={this.handleSubmit}>
                                <div className="form-cntnt">
                                    <div className="form-row">
                                        <>
                                            <div className="column-three">
                                                <div className="form-group ">
                                                    <label>
                                                        {
                                                            LANGUAGECONST[
                                                                this.props?.langData?.langType ||
                                                                    'ENG'
                                                            ].LABLES.Auditor
                                                        }
                                                    </label>
                                                    <select
                                                        name="auditor"
                                                        className={
                                                            'form-control ' +
                                                            (Validator.fieldValid('auditor')
                                                                ? 'valid'
                                                                : 'invalid')
                                                        }
                                                        onChange={(e) => {
                                                            this.handleChange(e);
                                                            Validator.showMessageFor('auditor');
                                                        }}
                                                        value={auditor}>
                                                        {auditorList.map(
                                                            (data, index) => (
                                                                <option
                                                                    key={index}
                                                                    role_name={data.role_name}
                                                                    value={data.user_id}>
                                                                    {data.first_name +
                                                                        ' ' +
                                                                        data.last_name}
                                                                </option>
                                                            ),
                                                            this
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="column-three">
                                                <div className="form-group">
                                                    <label>
                                                        {
                                                            LANGUAGECONST[
                                                                this.props?.langData?.langType ||
                                                                    'ENG'
                                                            ].LABLES.Reviewer
                                                        }
                                                    </label>
                                                    <select
                                                        name="reviewer"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleChange(e);
                                                            Validator.showMessageFor('reviewer');
                                                        }}
                                                        value={reviewer}>
                                                        {reviewerList.map(
                                                            (data, index) => (
                                                                <option
                                                                    key={index}
                                                                    role_name={data.role_name}
                                                                    value={data.user_id}>
                                                                    {data.first_name +
                                                                        ' ' +
                                                                        data.last_name}
                                                                </option>
                                                            ),
                                                            this
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            {fromEdit ? (
                                                <div className="column-three">
                                                    <div className="form-group ">
                                                        <label>
                                                            {
                                                                LANGUAGECONST[
                                                                    this.props?.langData
                                                                        ?.langType || 'ENG'
                                                                ].LABLES.EstimateDate
                                                            }
                                                        </label>
                                                        <DatePicker
                                                            name="estimatedEndDate"
                                                            value={this.state.estimatedDate}
                                                            className="form-control"
                                                            dateFormat="MM/dd/yyyy"
                                                            selected={this.state.estimatedDate}
                                                            onChange={this.handleInputChange}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            locale={
                                                                this.props?.langData?.language ||
                                                                'en'
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}
                                            {isPlantManager && (
                                                <div className="column-three">
                                                    <div className="form-group">
                                                        <label>
                                                            {
                                                                LANGUAGECONST[
                                                                    this.props?.langData
                                                                        ?.langType || 'ENG'
                                                                ].LABLES.AR_Reviewer
                                                            }
                                                        </label>
                                                        <select
                                                            name="ar_reviewer_id"
                                                            className="form-control"
                                                            onChange={this.setArReviewer}
                                                            value={ar_reviewer_id}>
                                                            <option value="" defaultChecked hidden>
                                                                {
                                                                    LANGUAGECONST[
                                                                        this.props?.langData
                                                                            ?.langType || 'ENG'
                                                                    ].STRINGS.SelectARReviewer
                                                                }
                                                            </option>
                                                            {userList.map((data, index) => (
                                                                <option
                                                                    key={index}
                                                                    role_name={data.role_name}
                                                                    value={data.user_id}
                                                                    ar_name={`${data.first_name} ${data.last_name}`}>
                                                                    {`${data.first_name} ${data.last_name}`}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="column-three">
                                                {this.props.location?.state?.fromEdit != 1 ? (
                                                    <div className="form-group">
                                                        <label>
                                                            {
                                                                LANGUAGECONST[
                                                                    this.props?.langData
                                                                        ?.langType || 'ENG'
                                                                ].LABLES.AuditType
                                                            }
                                                        </label>

                                                        <select
                                                            name="assetsType"
                                                            className="form-control"
                                                            disabled={fromEdit == 1}
                                                            onChange={(e) => {
                                                                this.handleAssetType(e);
                                                                Validator.showMessageFor(
                                                                    'assetsType'
                                                                );
                                                            }}
                                                            value={assetsType}>
                                                            <option
                                                                value=""
                                                                defaultValue={true}
                                                                hidden={true}>
                                                                {fromEdit == 1
                                                                    ? assetsType
                                                                    : LANGUAGECONST[
                                                                          this.props?.langData
                                                                              ?.langType || 'ENG'
                                                                      ].STRINGS
                                                                          .PleaseSelectAuditType}
                                                            </option>
                                                            {assetsTypeList.map(
                                                                (data, index) => (
                                                                    <option
                                                                        key={data.id}
                                                                        value={data.id}>
                                                                        {data.content ||
                                                                            data.asset_type}
                                                                    </option>
                                                                ),
                                                                this
                                                            )}
                                                        </select>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </>
                                        {this.props.location?.state?.fromEdit != 1 ? (
                                            <>
                                                <div className="column-three">
                                                    <div className="form-group">
                                                        <label>
                                                            {
                                                                LANGUAGECONST[
                                                                    this.props?.langData
                                                                        ?.langType || 'ENG'
                                                                ].LABLES.Area
                                                            }
                                                        </label>
                                                        <select
                                                            name="lineType"
                                                            className="form-control"
                                                            disabled={
                                                                fromEdit == 1 ||
                                                                this.state.isLineType
                                                            }
                                                            onChange={(e) => {
                                                                this.handleLineChange(e);
                                                                Validator.showMessageFor(
                                                                    'lineType'
                                                                );
                                                            }}
                                                            value={lineType}>
                                                            <option
                                                                value=""
                                                                defaultValue={true}
                                                                hidden={true}>
                                                                {fromEdit == 1
                                                                    ? lineType
                                                                    : LANGUAGECONST[
                                                                          this.props?.langData
                                                                              ?.langType || 'ENG'
                                                                      ].STRINGS.PleaseSelectArea}
                                                            </option>
                                                            {lineList.map(
                                                                (data, index) => (
                                                                    <option
                                                                        key={index}
                                                                        value={data}>
                                                                        {data.content || data}
                                                                    </option>
                                                                ),
                                                                this
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="column-three">
                                                    <div className="form-group">
                                                        <label>
                                                            {
                                                                LANGUAGECONST[
                                                                    this.props?.langData
                                                                        ?.langType || 'ENG'
                                                                ].LABLES.Asset
                                                            }
                                                        </label>
                                                        <select
                                                            name="assets"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleAssetChange(e);
                                                                Validator.showMessageFor('assets');
                                                            }}
                                                            value={assets}
                                                            disabled={
                                                                fromEdit == 1 || this.state.isAsset
                                                            }>
                                                            <option
                                                                value=""
                                                                defaultValue={true}
                                                                hidden={true}>
                                                                {fromEdit == 1
                                                                    ? assets
                                                                    : LANGUAGECONST[
                                                                          this.props?.langData
                                                                              ?.langType || 'ENG'
                                                                      ].STRINGS.SelectAsset}
                                                            </option>
                                                            {assetsList.map(
                                                                (data, index) => (
                                                                    <option
                                                                        key={index}
                                                                        value={data.asset_number}>
                                                                        {data.content ||
                                                                            data.asset_number}
                                                                    </option>
                                                                ),
                                                                this
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>

                                                {(assetsType == 5 || assetsType == 4) && assets && (
                                                    <div className="column-three">
                                                        <div className="form-group">
                                                            <label>
                                                                {
                                                                    LANGUAGECONST[
                                                                        this.props?.langData
                                                                            ?.langType || 'ENG'
                                                                    ].LABLES.Static_Utility
                                                                }
                                                            </label>
                                                            <select
                                                                name="utility_static_ques"
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    this.handleAdditionalUtility_Ques(
                                                                        e
                                                                    );
                                                                    Validator.showMessageFor(
                                                                        'utility_static_ques'
                                                                    );
                                                                }}
                                                                value={utility_static_ques}
                                                                disabled={
                                                                    fromEdit == 1 ||
                                                                    this.state.isType
                                                                }>
                                                                <option
                                                                    value=""
                                                                    defaultValue={true}
                                                                    hidden={true}>
                                                                    {
                                                                        LANGUAGECONST[
                                                                            this.props?.langData
                                                                                ?.langType || 'ENG'
                                                                        ].STRINGS.PleaseSelectAGA
                                                                    }
                                                                </option>
                                                                {assetsType == 5
                                                                    ? utility_static_ques_arr.map(
                                                                          (data, index) => (
                                                                              <option
                                                                                  key={index}
                                                                                  value={data}>
                                                                                  {data}
                                                                              </option>
                                                                          )
                                                                      )
                                                                    : facility_static_ques_arr.map(
                                                                          (data, index) => (
                                                                              <option
                                                                                  key={index}
                                                                                  value={data}>
                                                                                  {data}
                                                                              </option>
                                                                          )
                                                                      )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="column-three">
                                                    <div className="form-group">
                                                        <label>
                                                            {
                                                                LANGUAGECONST[
                                                                    this.props?.langData
                                                                        ?.langType || 'ENG'
                                                                ].LABLES.Activity
                                                            }
                                                        </label>
                                                        <select
                                                            name="type"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleActivity(e);
                                                                Validator.showMessageFor('type');
                                                            }}
                                                            value={this.state.type}
                                                            disabled={
                                                                fromEdit == 1 ||
                                                                this.state.isType ||
                                                                selectDV
                                                            }>
                                                            <option
                                                                value=""
                                                                defaultValue={true}
                                                                hidden={true}>
                                                                {
                                                                    LANGUAGECONST[
                                                                        this.props?.langData
                                                                            ?.langType || 'ENG'
                                                                    ].STRINGS.PleaseSelectActivity
                                                                }
                                                            </option>
                                                            {this.state.typeList.map(
                                                                (data, index) => (
                                                                    <option
                                                                        key={index}
                                                                        value={data}>
                                                                        {data}
                                                                    </option>
                                                                ),
                                                                this
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {this.props.location?.state?.fromEdit != 1 ? (
                                            <>
                                                <div className="column-three">
                                                    <div className="form-group ">
                                                        <label>
                                                            {
                                                                LANGUAGECONST[
                                                                    this.props?.langData
                                                                        ?.langType || 'ENG'
                                                                ].LABLES.EstimateDate
                                                            }
                                                        </label>
                                                        <DatePicker
                                                            name="estimatedDate"
                                                            value={this.state.estimatedDate}
                                                            onKeyPress={() =>
                                                                Validator.showMessageFor(
                                                                    'estimatedDate'
                                                                )
                                                            }
                                                            className="form-control"
                                                            dateFormat="MM/dd/yyyy"
                                                            selected={this.state.estimatedDate}
                                                            disabled={fromEdit == 1}
                                                            onChange={(date) =>
                                                                this.handleDate(
                                                                    'estimatedDate',
                                                                    date
                                                                )
                                                            }
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            locale={
                                                                this.props?.langData?.language ||
                                                                'en'
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                {assetsType == 3 && (
                                                    <div className="column-three">
                                                        <div>
                                                            {activityContainMH && (
                                                                <div className="form-group ">
                                                                    <label>
                                                                        {
                                                                            LANGUAGECONST[
                                                                                this.props?.langData
                                                                                    ?.langType ||
                                                                                    'ENG'
                                                                            ].STRINGS
                                                                                .SelectMHQuestion
                                                                        }
                                                                    </label>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectMH}
                                                                        onChange={this.handleMH}
                                                                    />
                                                                    <span>{mhActivityName}</span>
                                                                </div>
                                                            )}
                                                            {activityContainDV && (
                                                                <div className="form-group ">
                                                                    <label>
                                                                        {
                                                                            LANGUAGECONST[
                                                                                this.props?.langData
                                                                                    ?.langType ||
                                                                                    'ENG'
                                                                            ].STRINGS
                                                                                .SelectDVQuestion
                                                                        }
                                                                    </label>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectDV}
                                                                        onChange={this.handleDV}
                                                                    />
                                                                    <span>{dvActivityName}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                {assetsType == 1 && (
                                                    <div className="column-three">
                                                        <div className="form-group ">
                                                            <label>
                                                                {
                                                                    LANGUAGECONST[
                                                                        this.props?.langData
                                                                            ?.langType || 'ENG'
                                                                    ].LABLES.StartDate
                                                                }
                                                            </label>
                                                            <DatePicker
                                                                name="task_start_date"
                                                                placeholderText="Start Date"
                                                                className="form-control"
                                                                dateFormat="MM/dd/yyyy"
                                                                selected={task_start_date}
                                                                minDate={new Date(1514764800000)}
                                                                maxDate={new Date()}
                                                                disabled={fromEdit == 1}
                                                                onChange={(date) =>
                                                                    this.handleDate(
                                                                        'task_start_date',
                                                                        date
                                                                    )
                                                                }
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                locale={
                                                                    this.props?.langData
                                                                        ?.language || 'en'
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                {assetsType == 2 && (
                                                    <div className="column-three">
                                                        <div className="form-group">
                                                            <label>
                                                                {
                                                                    LANGUAGECONST[
                                                                        this.props?.langData
                                                                            ?.langType || 'ENG'
                                                                    ].LABLES.DESIREDCOMPLETION
                                                                }
                                                            </label>
                                                            <select
                                                                name="completionPercentage"
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    this.handleFieldChange(e);
                                                                    Validator.showMessageFor(
                                                                        'completionPercentage'
                                                                    );
                                                                }}
                                                                value={
                                                                    this.state.competionPercentage
                                                                }>
                                                                <option
                                                                    value=""
                                                                    defaultValue={true}
                                                                    hidden={true}>
                                                                    {
                                                                        LANGUAGECONST[
                                                                            this.props?.langData
                                                                                ?.langType || 'ENG'
                                                                        ].STRINGS
                                                                            .PleaseSelectPercentage
                                                                    }
                                                                </option>
                                                                {this.state.completionArr.map(
                                                                    (data, index) => (
                                                                        <option
                                                                            key={index}
                                                                            value={data}>
                                                                            {data}
                                                                        </option>
                                                                    ),
                                                                    this
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                )}

                                                {assetsType == 2 && vlmCheck == 'Yes' ? (
                                                    <div className="column-three">
                                                        <div className="form-group">
                                                            <label>
                                                                {
                                                                    LANGUAGECONST[
                                                                        this.props?.langData
                                                                            ?.langType || 'ENG'
                                                                    ].LABLES.UploadFile
                                                                }
                                                            </label>
                                                            <div className="upload-file-control">
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="2"
                                                                    name="csvFile"
                                                                    onChange={this.handleFile}
                                                                />
                                                                <label htmlFor="2">
                                                                    {
                                                                        LANGUAGECONST[
                                                                            this.props?.langData
                                                                                ?.langType || 'ENG'
                                                                        ].LABLES.UploadFile
                                                                    }{' '}
                                                                    {fileName}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    assetsType == 2 && (
                                                        <div className="column-three"></div>
                                                    )
                                                )}

                                                {assetsType == 1 && (
                                                    <div className="column-three">
                                                        <div className="form-group ">
                                                            <label>
                                                                {
                                                                    LANGUAGECONST[
                                                                        this.props?.langData
                                                                            ?.langType || 'ENG'
                                                                    ].LABLES.EndDate
                                                                }
                                                            </label>
                                                            <DatePicker
                                                                name="task_end_date"
                                                                placeholderText="End Date"
                                                                className="form-control"
                                                                dateFormat="MM/dd/yyyy"
                                                                selected={task_end_date}
                                                                minDate={
                                                                    Date.parse(task_start_date) +
                                                                    86400000
                                                                }
                                                                maxDate={new Date()}
                                                                disabled={
                                                                    fromEdit == 1 ||
                                                                    !task_start_date
                                                                }
                                                                onChange={(date) =>
                                                                    this.handleDate(
                                                                        'task_end_date',
                                                                        date
                                                                    )
                                                                }
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                locale={
                                                                    this.props?.langData
                                                                        ?.language || 'en'
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="submition-outer">
                                        {assetsType == 1 && (
                                            <button
                                                type="button"
                                                className="btn-prmry"
                                                name="searchWO"
                                                onClick={this.serachWO}>
                                                {
                                                    LANGUAGECONST[
                                                        this.props?.langData?.langType || 'ENG'
                                                    ].LABLES.Validate
                                                }
                                            </button>
                                        )}
                                        {assetsType == 2 && (
                                            <button
                                                type="button"
                                                className="btn-prmry"
                                                name="getOhqDataList"
                                                onClick={this.getOhqDataList}
                                                disabled={
                                                    assetsType == 2 &&
                                                    !(vlmCheck == 'No' || enableBtn)
                                                }>
                                                {
                                                    LANGUAGECONST[
                                                        this.props?.langData?.langType || 'ENG'
                                                    ].STRINGS.FetchEBSData
                                                }
                                            </button>
                                        )}
                                        <button
                                            className={
                                                Validator.allValid()
                                                    ? 'btn-prmry'
                                                    : 'btn-prmry disabled'
                                            }
                                            name="AddTask"
                                            value="AddTask"
                                            disabled={
                                                !Validator.allValid() ||
                                                (fromEdit != 1 && updateQuesArr.length == 0) ||
                                                (assetsType == 2 && !isEnable) ||
                                                (assetsType == 1 && !checkWOSearch)
                                            }>
                                            {fromEdit == 1 ? 'Update Task' : 'Add task'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <div>
                                <div>
                                    <div
                                        className="form-cntnt"
                                        style={{ padding: '30px 30px 10px 30px' }}>
                                        <div className="form-row">
                                            <div className="column-three">
                                                <div className="form-group ">
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.PlantName
                                                    }
                                                    :
                                                    <label
                                                        style={{
                                                            display: 'unset',
                                                            marginLeft: '5px'
                                                        }}>
                                                        {this.props?.location?.state?.plant_name}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="column-three">
                                                <div className="form-group ">
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.PlantDirector
                                                    }
                                                    :
                                                    <label
                                                        style={{
                                                            display: 'unset',
                                                            marginLeft: '5px'
                                                        }}>
                                                        {
                                                            this.props?.location?.state
                                                                ?.plant_director
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="column-three">
                                                <div className="form-group ">
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.AuditBeginDate
                                                    }
                                                    :
                                                    <label
                                                        style={{
                                                            display: 'unset',
                                                            marginLeft: '5px'
                                                        }}>
                                                        {formatDate(
                                                            this.props?.location?.state?.start_date
                                                        )}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="column-three">
                                                <div className="form-group ">
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.AuditEndDate
                                                    }
                                                    :
                                                    <label
                                                        style={{
                                                            display: 'unset',
                                                            marginLeft: '5px'
                                                        }}>
                                                        {formatDate(
                                                            this.props?.location?.state?.end_date
                                                        )}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="column-three">
                                                <div className="form-group ">
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.LastAuditedDate
                                                    }
                                                    :
                                                    <label
                                                        style={{
                                                            display: 'unset',
                                                            marginLeft: '5px'
                                                        }}>
                                                        {formatDate(
                                                            this.props?.location?.state
                                                                ?.last_updated_on
                                                        )}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="column-three">
                                                <div className="form-group ">
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.Auditor
                                                    }
                                                    :
                                                    <label
                                                        style={{
                                                            display: 'unset',
                                                            marginLeft: '5px'
                                                        }}>
                                                        {this.props?.location?.state?.auditor_name}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!getData('reviewer') &&
                        fromEdit != 1 &&
                        this.getAssetType(this.state.assetsType)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const data = state.data;
    return data;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(taskDataAction, dispatch),
        uploadFile: (url, data, path, method, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithAction(url, data, path, method, loaderMsg, obj, language))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskComponent);
